import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LazyLoader from './shared/components/LazyLoader/load';
import PrivateRoutes from './modules/authentication/components/Layout/privateRoutes';
import PublicRoutes from './modules/authentication/components/Layout/publicRoutes';
import SharedRoutes from './modules/authentication/components/Layout/sharedRoutes';
import { Profile } from './modules/createJob/components/Candidates/Profile/Profile';
import ComparePage from './pages/ComparePage';
import { reportsLayout } from './shared/utils/interfaces/reports';
import { automationActionsTabs, settingsTabs } from './shared/utils/interfaces/settingsInterface';
import { Departments } from './pages/Departments';
import SimilarityPage from './pages/SimilarityPage';
// import { InterviewTemplate } from './modules/interview/components/templates/InterviewTemplate';
const SearchPage = lazy(() => import('./pages/SearchPage'));
const TemplatesActions = lazy(() => import('./modules/settings/components/AutomatedActions/TemplatesActions'));
const MyActions = lazy(() => import('./modules/settings/components/AutomatedActions/MyActions'));
const AutomatedAction = lazy(() => import('./modules/settings/components/AutomatedActions/AutomatedAction'));
const AutomatedActions = lazy(() => import('./modules/settings/components/AutomatedActions/AutomatedActions'))
const EmailTemplates = lazy(() => import('./modules/settings/components/EmailTemplates/EmailTemplates'));
const LinkDomain = lazy(() => import('./modules/settings/components/LinkDomainSteps/LinkDomain'));
const Pipelines = lazy(() => import('./modules/settings/components/Pipelines/Pipelines'));
const SettingsProfile = lazy(() => import('./modules/settings/components/SettingsProfile'));
const EmailIntegration = lazy(() => import('./modules/settings/components/EmailIntegration/EmailIntegration'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const CreateJobPage = lazy(() => import('./pages/createJobPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignupPage = lazy(() => import('./pages/SignupPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const JobsListPage = lazy(() => import('./pages/JobsListPage'));
const JobPublicView = lazy(() => import('./pages/JobPublicViewPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const RefundPolicyPage = lazy(() => import('./pages/RefundPolicyPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const CompanyCareerPage = lazy(() => import('./pages/CompanyCareerPage'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const ValidInvitation = lazy(() => import('./modules/createJob/components/Candidates/CheckInvitation/ValidInvitation'));

export const routes = (
    <BrowserRouter>
        <Suspense fallback={<LazyLoader />}>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="pricing" element={<PricingPage />} />
                {/* shared routes / shows <Nav /> with Sign up and Login buttons on public views and with user profile dropdown on private views */}
                <Route path="/" element={<SharedRoutes />}>
                    <Route path="terms" element={<TermsPage />} />
                    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="refund-policy" element={<RefundPolicyPage />} />
                    <Route path="contact-us" element={<ContactUsPage />} />
                    <Route path="company/:companyName" element={<CompanyCareerPage />} />
                    <Route path="jobs/:jobId" element={<JobPublicView />} />
                    <Route path="invitation/:invitationId" element={<ValidInvitation />} />
                </Route>
                {/* private route / shows <Nav /> as Logged in with user profile dropdown on private views */}
                <Route path="/" element={<PrivateRoutes />}>
                    <Route path="jobs" element={<JobsListPage />} />
                    <Route path="jobs/:jobId/candidates" element={<CreateJobPage />} />
                    <Route path="generateJob/:jobId?" element={<CreateJobPage />} />
                    <Route path="settings" element={<SettingsPage />}>
                        <Route path={settingsTabs.profile} element={<SettingsProfile />} />
                        <Route path={settingsTabs.templates} element={<EmailTemplates />} />
                        {/* <Route path={settingsTabs.interview} element={<InterviewTemplate />}/> */}
                        <Route path={settingsTabs.automatedActions} element={<AutomatedActions />} >
                            <Route path={automationActionsTabs.templatesActions} element={<TemplatesActions />} />
                            <Route path={automationActionsTabs.myActions} element={<MyActions />} />
                        </Route>
                        <Route path={`${settingsTabs.automatedActions}/${automationActionsTabs.templatesActions}/:automationTemplateId`} element={<AutomatedAction />} />
                        <Route path={`${settingsTabs.automatedActions}/${automationActionsTabs.myActions}/:actionId`} element={<AutomatedAction />} />
                        <Route path={settingsTabs.pipelines} element={<Pipelines />} />
                        <Route path={settingsTabs.linkDomain} element={<LinkDomain />} />
                        <Route path={settingsTabs.emailIntegration} element={<EmailIntegration />} />
                        <Route path={settingsTabs.department} element={<Departments />} />
                    </Route>
                    <Route path="jobs/:jobId/candidates/:applicationId" element={<Profile />} />
                    <Route path="jobs/:jobId/candidates/compare/:ids" element={<ComparePage />} />
                    <Route path="reports" element={<ReportsPage layout={reportsLayout.main} />} />
                    <Route path="reports/:category" element={<ReportsPage layout={reportsLayout.category} />} />

                    <Route path='search' element={<SearchPage />} />
                    <Route path='similarApplication/:applicationId?' element={<SimilarityPage />} />
                </Route>
                {/* public route / doesn't show <Nav /> at all on public views */}
                <Route path="/" element={<PublicRoutes />}>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="reset-password" element={<ResetPasswordPage />} />
                    <Route path="signup" element={<SignupPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    </BrowserRouter>
);
