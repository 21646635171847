import { apiSlice } from "../../../shared/slices/apiSlice";
import { tagTypes } from "../../../shared/utils/constantData";
import { ICreateEvaluation, IKit } from "../../../shared/utils/interfaces/interviewKit";
const { comments } = tagTypes
export const interviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    createNewInterviewKit: builder.mutation({
      query: (initialKitData: any) => ({
        url: "/interviews/kits",
        method: "POST",
        body: initialKitData,
      }),
    }),
    generateNewSection: builder.mutation({
      query: (initialSectionData: { jobId: number; sectionTitle: string }) => ({
        url: "/interviews/kits/sections",
        method: "POST",
        body: initialSectionData,
      }),
    }),
    getAllKits: builder.query({
      query: (JobId: number) => ({
        url: `/interviews/kits/jobs/${JobId}`,
      }),
    }),
    getKitById: builder.query({
      query: (kitId: number) => ({
        url: `/interviews/kits/${kitId}`,
      }),
    }),
    getKitTemplateById: builder.query({
      query: (templateId: number) => ({
        url: `/interviews/templates/${templateId}`,
      }),
    }),
    getEvaluationKitByCandidateId: builder.query({
      query: (applicationId: number) => ({
        url: `/interviews/kit?applicationId=${applicationId}`,
      }),
    }),
    getEvaluationById: builder.query({
      query:(evaluationId: number) => ({
        url: `/interviews/evaluations/${evaluationId}`,
      })
    }),
    deleteEvaluation: builder.mutation({
      query: (evaluationId: number) => ({
        url: `/interviews/evaluations/${evaluationId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result: any, error: any) => error ? [] : [comments]
    }),
    createEvaluation: builder.mutation({
      query: (evaluationData: ICreateEvaluation) => ({
        url: "/interviews/evaluations",
        method: "POST",
        body: evaluationData,
      }),
      invalidatesTags: (_result: any, error: any) => error ? [] : [comments]
    }),
    EditEvaluation: builder.mutation({
      query: (evaluationData: ICreateEvaluation) => ({
        url: "/interviews/evaluations",
        method: "PUT",
        body: evaluationData,
      }),
      invalidatesTags: (_result: any, error: any) => error ? [] : [comments]
    }),
    updateKit: builder.mutation({
      query: (kitData: IKit) => ({
        url: "/interviews/kits",
        method: "PUT",
        body: kitData,
      }),
    }),
    saveKitAsTemplate: builder.mutation({
      query: (kitData: IKit) => ({
        url: "/interviews/templates",
        method: "POST",
        body: kitData,
      }),
    }),
  }),
});
export const {
  useCreateNewInterviewKitMutation,
  useGetAllKitsQuery,
  useLazyGetKitByIdQuery,
  useLazyGetKitTemplateByIdQuery,
  useLazyGetEvaluationKitByCandidateIdQuery,
  useCreateEvaluationMutation,
  useGenerateNewSectionMutation,
  useUpdateKitMutation,
  useSaveKitAsTemplateMutation,
  useDeleteEvaluationMutation,
  useLazyGetEvaluationByIdQuery,
  useEditEvaluationMutation,
} = interviewApiSlice;
