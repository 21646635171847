import { RoleType } from "./AuthInterface";
import { tabs } from "./createJobTabs";
import { IPipeline } from "./pipelineInterface";
import { automatedActionStatus } from "./settingsInterface";
export interface IHiringStage {
    id: number;
    name: tabs;
    count: string;
}

export enum GlobalStageTypesId {
    candidates = 1,
    shortlist,
    assessment,
    interview,
    offer,
    hired,
    rejected,
}

export interface ICandidate {
    id: number
    firstName: string
    lastName: string
    email: string
    phone: number
    cv: string
    coverLetter: string | null
    cvScore: number | null
    cvSummary: string | null
    longSummary: string | null
    comments: []
    pipelineStages: IPipeline[]
    pipelineHiringStageId: number,
    numberOfComments: number,
    jobId: number,
    interviewQuestions: string[]
    strengths: string[],
    weaknesses: string[],
    applicationCriteriasEvaluation: ICriteria[],
    batchId: number,
    isSimilar: boolean,
    cvFileName: string,
    userRole: { roleName: string }
    department?: {
        companyId: number,
        createdAt: string,
        id: number,
        name: string,
        parentDepartment?: {
            name: string,
        }
    }
    pipelineStage: {
        id: number,
        pipelineId: number,
        name: string,
        companyStageTypeId: number,
        isLocked: boolean,
        order: number,
        companyStageType: {
            id: number,
            companyId: number,
            name: string,
            globalStageTypeId: GlobalStageTypesId,
            order: number,
            isLocked: boolean,
        }
    },
    hasKit: boolean,
    canEvaluate: boolean,
}
export interface ICriteria {
    applicationId: number
    id: number
    jobCriteria: {
        criteriaName: string
        id: number
        isMain: boolean
        jobId: number
    }
    jobCriteriaId: number
    score: string
    summary: string,
    longSummary: string | null,
}

export interface ICandidatesFilter {
    page: number,
    perPage: number,
    sortBy: string,
    hiringStageId: number
}
export const candidatesSorting = [
    {
        value: 'highestScore',
        label: 'Sort by Highest Score'
    },
    {
        value: 'mostRecent',
        label: 'Sort by Most Recent'
    },
    {
        value: 'az',
        label: 'Sort by A-Z'
    }
]
export const SearchCandidatesSorting = [
    {
        value: 'mostrecent',
        label: 'Sort by Most Recent'
    },
    {
        value: 'bestmatch',
        label: 'Sort by Best Match'
    },
    {
        value: 'az',
        label: 'Sort by A-Z'
    }
]
export interface IHiringStageModi {
    label: string;
    key: number;
    disabled: boolean,
    danger?: boolean
}

export interface IChangeCandidateStage {
    applicationId: number;
    hiringStageId: number;
}

export interface IInvitationData {
    email: string,
    roleId: number,
    objectId: number, // in case job >> objectId will be jobId , in case company  >> objectId will be companyId
    ObjectType: RoleType,
    isTopLevel: boolean,
    id: number
}

export interface IUpdateUserRoleBody {
    userId: number,
    roleId: number,
    objectId: number
    objectType: RoleType
}

export enum currentTypes {
    CV = "CV",
    coverLetter = "COVER_LETTER",
    Graph = "GRAPH"
}
export interface IAiSearchBody {
    jobId: string | undefined,
    hiringStageId: number,
    query: string,
    searchMethodId: number,
    perPage?: number,
    page?: number
}

export interface ISearchParams {
    jobsIds?: number[] | undefined,
    pipelineHiringStageIds?: number[],
    searchQuery?: string,
    sortBy?: string,
    perPage?: number,
    page?: number,
    departmentIds?: string
}
export interface IAIFilter {
    searchMethodId: number,
    page: number,
    perPage: number,
}

export interface IAutomatedNotification {
    id: number,
    status: automatedActionStatus,
    statusId?: number
    statusReason: string,
    leftTime: number,
}
export interface ISearchFilter {
    key: string,
    label: string
}
export interface ISearchCandidate {
    id: number;
    firstName: string;
    lastName: string;
    name: string;
    currentPosition: string;
    email: string;
    pipelineHiringStageId: number;
    snippet?: string;
    createdAt?: string;
    title: string;
    jobId: number
    stageName: string;
    cvFileName: string;
    batchId?: string;
    userRole: { roleName: string };
    department?: {
        companyId?: number,
        createdAt: string,
        id: number,
        name: string,
        parentDepartmentId: number,
        parentDepartment: {
            name: string
        }
    }
    isSimilar?: boolean;
    similarityTypes?: string[];
    phone?: string
    pipelineStage?: {
        id: number,
        pipelineId: number,
        name: string,
        companyStageTypeId: number,
        isLocked: boolean,
        order: number,
        companyStageType: {
            id: number,
            companyId: number,
            name: string,
            globalStageTypeId: GlobalStageTypesId,
            order: number,
            isLocked: boolean,
        }
    }
}

export interface IChangeCandidateJobBody {
    currentJobId: number,
    newJobId: number,
    pipelineStageId: number,
    applicationId: number
}
export interface ISearchCandidateSFilter {
    page: number,
    perPage: number,
    sortBy: string
}