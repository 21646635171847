import ModalComponent from '../../../../shared/components/Modals/ModalComponent'
import ModalBody from '../../../../shared/components/Modals/ModalBody'
import { Form, Divider, Button } from 'antd'
import { Questions } from '../../../createJob/components/Candidates/Profile/Evaluation/Questions'
import { ISection } from '../../../../shared/utils/interfaces/interviewKit';
import { useSelector, useDispatch } from "react-redux";
import { selectShowPreview, setShowPreview } from "../../slice/interviewSlice";
import BackArrowIcon from '../../../../shared/assets/images/ChevronLeft.svg'
import { setSettingsModalOpen } from "../../../settings/slice/settingsSlice"


export const PreviewQuestions = ({ kitTemplateData, kitTemplateTitle, fromProfile = false }: { kitTemplateData: ISection[], kitTemplateTitle: String, fromProfile: Boolean }) => {
  const showPreview = useSelector(selectShowPreview)
  const dispatch = useDispatch();


  const close = () => {
    dispatch(setShowPreview(false));
    dispatch(setSettingsModalOpen(true))
  }
  return (
    <ModalComponent
      visibleModal={showPreview}
      handleCloseModal={close}
      width={1010}
      content={
        <ModalBody
          title={<>
            <div className='flex gap-2'>
              {
                !fromProfile ? <Button
                  className={` !border-none !shadow-none disabled:bg-transparent`}
                  onClick={close}
                >
                  <img src={BackArrowIcon} className='inline' alt="+" />
                </Button> : ''
              }
              <p className="flex items-center">
                <span className='mb-3.5 capitalize'>{kitTemplateTitle ? kitTemplateTitle : ''} </span>
              </p>
              <p className="font-['Inter'] text-gray-600 font-normal text-xl">
                (Preview)
              </p>
            </div>
            <Divider className='m-0' />
          </>}
        >
          <div className="w-full max-w-[100rem] p-4 mx-auto sm:p-6 h-[34.5rem] overflow-auto">
            <Form layout="vertical" className="space-y-8">
              {kitTemplateData?.map((section: any, sectionIndex: any) => (
                <div key={sectionIndex}>
                  <p className="text-xs font-semibold text-gray-900 uppercase border-b text-left pb-2 !space-y-2 mb-2">{section?.title}</p>
                  <div className='!m-0'>
                    <Questions
                      section={section}
                      sectionIndex={sectionIndex}
                      onlyView={true} />
                  </div>
                </div>
              ))}
            </Form>
          </div>
        </ModalBody>
      }
    />
  )
}
